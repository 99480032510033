<script setup lang="ts">
import type { CmsElementManufacturerLogo } from "@shopware/composables";
import { useCmsElementImage, useUrlResolver } from "#imports";
import { buildUrlPrefix } from "@shopware/helpers";

const props = defineProps<{
  content: CmsElementImage | CmsElementManufacturerLogo;
}>();

const { getUrlPrefix } = useUrlResolver();
const { imageContainerAttrs, imageAttrs, imageLink, isVideoElement, mimeType } =
  useCmsElementImage(props.content);

const imageComputedContainerAttrs = computed(() => {
  const imageAttrsCopy = Object.assign({}, imageContainerAttrs.value);
  if (imageAttrsCopy?.href) {
    imageAttrsCopy.href = buildUrlPrefix(
      imageAttrsCopy.href,
      getUrlPrefix(),
    ).path;
  }
  return imageAttrsCopy;
});

const { containerStyle, displayMode } = useCmsElementImage(props.content);
</script>
<template>
  <component
    :is="imageLink.url ? 'a' : 'div'"
    v-if="imageAttrs.src"
    :style="containerStyle"
    class="cms-element-image relative w-full"
    v-bind="imageComputedContainerAttrs"
  >
    <video v-if="isVideoElement" controls class="object-contain">
      <source :src="imageAttrs.src" :type="mimeType" />
      {{ $t("errors.no-video-tag-support") }}
    </video>

    <div
      class="flex justify-center h-full"
      :style="{
        justifyContent: props?.content?.config?.horizontalAlign?.value,
        alignItems: props?.content?.config?.verticalAlign?.value,
      }"
    >
      <SharedMediaImage
        :media="content.data.media"
        :alt="imageAttrs.alt"
        class="object-contain"
        loading="lazy"
        :dpr-scaling="false"
        :resizing-type="
          props?.content?.config?.displayMode?.value == 'cover'
            ? 'fill'
            : undefined
        "
      />
    </div>
  </component>
</template>
